import { graphql } from 'gatsby';
import React, { FC } from 'react';

import SEO from 'src/components/Seo/Seo';
import Hero from 'src/components/Hero';
import { HeadingScope } from 'src/components/Heading';
import Main from 'src/components/Main';
import Section from 'src/components/Section';
import CardsSlider from 'src/components/CardsSlider';
import CardsGrid from 'src/components/CardsGrid';
import { PartnerGrid } from 'src/components/Partner';
import VoucherPreview from 'src/components/VoucherPreview';
import Instagram from 'src/components/Instagram';
import { links } from 'src/components/MultistepForm/shared/constants';
import NovaturasSection from 'src/components/NovaturasSection';

interface IndexPageProps {
  data: any;
}

const IndexPage: FC<IndexPageProps> = ({ data }) => {
  const {
    wpPage: { homepageFields: acfFields, seo },
    products,
    hero,
  } = data;

  return (
    <HeadingScope>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={seo.opengraphImage?.localFile}
      />

      <Hero image={hero} />
      <Main>
        <Section title="Geriausiai įvertintos kelionės">
          <CardsSlider cards={products.nodes} />
        </Section>
        <Section title="Ištrūk į savo svajonių šalį">
          <CardsGrid
            columns={4}
            cards={acfFields?.countriesCardsTop?.map(node => ({
              image:
                node?.countryCard.image?.localFile.childImageSharp
                  ?.gatsbyImageData,
              title: node?.countryCard.title,
              link: node?.countryCard.link,
              button: true,
            }))}
            button={links.COUNTRIES}
          />
        </Section>
        <VoucherPreview />
        <Section title="Mūsų partneriai">
          <PartnerGrid partners={acfFields?.partners} />
        </Section>
        <NovaturasSection />
        <Section title="Sekite mus">
          <Instagram photos={acfFields?.instagram} />
        </Section>
      </Main>
    </HeadingScope>
  );
};

export const query = graphql`
  query Homepage {
    allWpPage(filter: { slug: { ne: "checkout" } }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    wpPage(databaseId: { eq: 107 }) {
      homepageFields {
        countriesCardsTop {
          countryCard {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 520)
                }
              }
              altText
            }
            link
            title
          }
        }
        partners {
          title
          link
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 90)
              }
            }
          }
        }
        instagram {
          id
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 640)
            }
          }
        }
      }
      ...YoastSeoPage
    }
    products: allWpProduct(
      filter: {
        productTags: { nodes: { elemMatch: { name: { eq: "Populiaru" } } } }
      }
    ) {
      nodes {
        ... on WpSimpleProduct {
          name
          slug
          regularPrice
          salePrice
          productFields {
            days
            people
            fullPrice
            fullPriceWithDiscount
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 720)
              }
            }
          }
          productCategories {
            nodes {
              slug
              name
              wpParent {
                node {
                  slug
                }
              }
            }
          }
          productTags {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    hero: file(relativePath: { eq: "piligrimo-keliones.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1800)
      }
    }
  }
`;

export default IndexPage;
