import { Link } from 'gatsby';
import styled from 'styled-components';
import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Tag from 'src/components/Tag';
import IconLabel, { IconSize } from 'src/components/IconLabel';
import { GlobeIcon, CalendarIcon } from 'src/assets/icons';
import Heading, { HeadingScope, HeadingVariant } from 'src/components/Heading';
import { colors } from 'src/theme/colors';
import { isArrayEmpty } from 'src/utils/helpers';

const ImageWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  height: 300px;
  max-width: 220px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: auto;
`;

const StyledTravelCard = styled(Link)`
  & img {
    cursor: pointer;
  }

  &:hover ${ImageWrapper} {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    transform: translateY(-5px);
  }
  max-width: 220px;

  text-decoration: none;
`;

const Image = styled(GatsbyImage)`
  border-radius: 10px;
  flex: 1;
`;

const Tags = styled.div`
  left: 10px;
  position: absolute;
  top: 20px;
  z-index: 1;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const Info = styled.div`
  align-items: flex-start;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  left: 10px;
  position: absolute;
  z-index: 2;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const MainInfo = styled(HeadingScope)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Title = styled(Heading)`
  margin: 20px 0;
  max-width: 220px;
  min-height: 38px;
  position: relative;

  &:after {
    background-color: ${colors.blue300};
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    width: 20px;
  }
`;

const PriceWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const Price = styled.span`
  color: ${colors.blue500};
  font-size: 20px;
  font-weight: 700;
`;

const RegularPrice = styled.span`
  color: ${colors.blue300};
  font-size: 16px;
  margin-left: 10px;
  text-decoration: line-through;
`;

interface ProductTags {
  slug: string;
  name: string;
}

interface TravelCardProps {
  tags?: Array<ProductTags>;
  url: string;
  image: any;
  location?: string; //TODO required
  duration: string;
  title: string;
  regularPrice: string;
  salePrice?: string;
}

const TAGS_LIMIT = 2;

const TravelCard: FC<TravelCardProps> = ({
  tags,
  url,
  image,
  location,
  duration,
  title,
  regularPrice,
  salePrice,
}) => {
  return (
    <StyledTravelCard to={url}>
      <ImageWrapper>
        {image && (
          <Image image={getImage(image.localFile)} alt={image.altText} />
        )}
        {tags && !isArrayEmpty(tags) && (
          <Tags>
            {tags?.map(
              (tag, index) =>
                index < TAGS_LIMIT && <Tag key={tag.slug}>{tag.name}</Tag>
            )}
          </Tags>
        )}
        <Info>
          {location && (
            <IconLabel
              icon={<GlobeIcon />}
              label={location}
              size={IconSize.SMALL}
              isBiggerText
            />
          )}
          <IconLabel
            icon={<CalendarIcon />}
            label={duration}
            size={IconSize.SMALL}
            isBiggerText
          />
        </Info>
      </ImageWrapper>
      <MainInfo>
        <Title variant={HeadingVariant.ECHO}>{title}</Title>
        {salePrice ? (
          <PriceWrapper>
            <Price>{salePrice}</Price>
            <RegularPrice>{regularPrice}</RegularPrice>
          </PriceWrapper>
        ) : (
          <Price>{regularPrice}</Price>
        )}
      </MainInfo>
    </StyledTravelCard>
  );
};

export default TravelCard;
