import React, { FC } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { mediaQuery } from 'src/theme/breakpoints';

const Posts = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 20px;

  ${mediaQuery('md')} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mediaQuery('lg')} {
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(6, 1fr);
  }
`;

const Post = styled.a`
  width: 100%;
`;

const PostImage = styled(GatsbyImage)`
  max-width: 100%;
`;

interface InstagramProps {
  photos: any;
}

const Instagram: FC<InstagramProps> = ({ photos }) => {
  return (
    <Posts>
      {photos &&
        photos.map(photo => (
          <Post
            href="https://www.instagram.com/piligrimas_travel/"
            target="_blank"
            rel="noopener noreferrer"
            key={photo.id}
          >
            <PostImage image={getImage(photo.localFile)} alt={photo.altText} />
          </Post>
        ))}
    </Posts>
  );
};

export default Instagram;
