import TextLoop from 'react-text-loop';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import React, { FC } from 'react';

import ButtonsGroup from 'src/components/ButtonsGroup';
import Button, { ButtonAppearance } from 'src/components/Button';
import StatsIcon from 'src/components/StatsIcon';
import { mediaQuery } from 'src/theme/breakpoints';
import { colors } from 'src/theme/colors';
import { BalloonIcon, GlobeIcon, EuroIcon, SmileIcon } from 'src/assets/icons';
import { links } from 'src/components/MultistepForm/shared/constants';

const BackgroundWrapper = styled.div`
  height: 100vh;
  padding: 120px 0 20px;
  position: relative;
  width: 100%;

  ${mediaQuery('md')} {
    height: 100vh;
    padding: 80px 0 20px;
  } ;
`;

const Background = styled(GatsbyImage)<HeroProps>`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-area: 1/1;
  justify-content: center;
  margin: 0 auto;
  max-width: 1366px;
  padding: 0 20px;
  z-index: 1;
`;

const Subheading = styled.p`
  color: ${colors.white};
  font-size: 18px;
  line-height: 1.5;
  margin: 0;

  ${mediaQuery('md')} {
    font-size: 22px;
  }

  ${mediaQuery('lg')} {
    font-size: 28px;
  } ;
`;

const LoopingText = styled.p`
  color: ${colors.white};
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 4px;
  line-height: 1.4;
  margin: 0;
  text-transform: uppercase;

  ${mediaQuery('md')} {
    font-size: 70px;
  } ;
`;

const SmallText = styled.p`
  color: ${colors.white};
  font-size: 14px;
  margin: 14px 0;
  text-align: center;
`;

const StatsWrapper = styled.div`
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin: 40px auto 0;
  max-width: 300px;
  width: 100%;

  ${mediaQuery('sm')} {
    grid-template-columns: repeat(4, 1fr);
    margin: 80px auto 20px;
    max-width: 800px;
  } ;
`;

interface HeroProps {
  image: any;
}

const Hero: FC<HeroProps> = ({ image }) => {
  return (
    <BackgroundWrapper>
      <Background image={getImage(image)} alt="" />
      <Content>
        <Subheading>Atėjo metas naujiems</Subheading>
        <TextLoop>
          <LoopingText>Nuotykiams</LoopingText>
          <LoopingText>Iššūkiams</LoopingText>
          <LoopingText>Potyriams</LoopingText>
          <LoopingText>Atradimams</LoopingText>
        </TextLoop>
        <SmallText>Rinkis jau paruoštą kelionę pagal</SmallText>
        <ButtonsGroup />
        <SmallText>Arba susikurk savo svajonių maršrutą pats</SmallText>
        <Button url={links.TRIP_FORM} appearance={ButtonAppearance.BRAVO}>
          Kurti unikalų kelionės planą
        </Button>
        <StatsWrapper>
          <StatsIcon
            icon={<BalloonIcon />}
            statTitle="1200+"
            statInfo="Nuotykiai"
          />
          <StatsIcon icon={<GlobeIcon />} statTitle="100+" statInfo="Šalys" />
          <StatsIcon
            icon={<EuroIcon />}
            statTitle="Kainos"
            statInfo="garantija"
          />
          <StatsIcon
            icon={<SmileIcon />}
            statTitle="1000+"
            statInfo="Laimingi klientai"
          />
        </StatsWrapper>
      </Content>
    </BackgroundWrapper>
  );
};

export default Hero;
