import { categoryFields } from 'src/components/MultistepForm/shared/constants';
import { CategoryFields } from 'src/components/MultistepForm/shared/types';

export const getMappedProductCategories = (
  { nodes: productCategories },
  acfFields: {
    days: number;
    people: number;
  }
): CategoryFields => {
  const categories = {};

  // get all parent categories with child category slugs
  productCategories.forEach(category => {
    const parentSlug = category?.wpParent?.node.slug;
    const parentCategory = categories[parentSlug];

    const childCategory = { slug: category.slug, name: category.name };

    categories[parentSlug] = parentCategory
      ? // if parent category exist push child category slug
        [...parentCategory, childCategory]
      : // else if parent category doesn't exist create new with child category slug
        [childCategory];
  });

  // map customFields as string into categories object
  if (acfFields) {
    categories[categoryFields.PEOPLE] = [String(acfFields.people)];
    categories[categoryFields.DAYS] = [String(acfFields.days)];
  }

  return <CategoryFields>categories;
};

const getMappedProductTags = ({ nodes: productTags }) => {
  return productTags.map(tag => ({ slug: tag.slug, name: tag.name }));
};

export const getMappedProducts = (products): any => {
  return products.map(product => ({
    ...product,
    productCategories: getMappedProductCategories(
      product.productCategories,
      product.productFields
    ),
    productTags: getMappedProductTags(product.productTags),
  }));
};

export const getStringifiedCategoryValues = (
  category: [{ name: string }]
): string | undefined => {
  if (!category) return;

  const valuesArray = category.map(cat => cat.name);
  return valuesArray.join(', ');
};
