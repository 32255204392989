import styled, { css } from 'styled-components';
import React, { FC } from 'react';

import { ArrowIcon } from 'src/assets/icons';
import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';

const Button = styled.button<SliderButtonProps>`
  align-items: center;
  background-color: ${colors.white};
  border: none;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  display: flex;
  height: 55px;
  justify-content: center;
  left: -20px;
  position: absolute;
  top: 132px;
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 55px;
  z-index: 2;

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  ${mediaQuery('md', 'max')} {
    display: none;
  }

  & svg {
    transform: ${props => (props.isNext ? 'rotateY(180deg)' : 'none')};
  }

  ${({ isNext }) =>
    isNext &&
    css`
      left: auto;
      right: -20px;
    `}
`;

interface SliderButtonProps {
  isNext?: boolean;
  onClick?: () => void;
}

const SliderButton: FC<SliderButtonProps> = ({ isNext, onClick }) => (
  <Button isNext={isNext} onClick={onClick}>
    <ArrowIcon />
  </Button>
);

export default SliderButton;
