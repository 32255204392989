export const isObjectEmpty = (obj: Record<string, unknown>): boolean =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const isArrayEmpty = (array: any[]): boolean =>
  !Array.isArray(array) || !array.length;

export const setToStorage = (key: string, value: string): void =>
  sessionStorage.setItem(key, value);

export const getFromStorage = (key: string): string | null =>
  sessionStorage.getItem(key);

export const removeFromStorage = (key: string): void =>
  sessionStorage.removeItem(key);

export const getJoinedArrayByComma = (arr: string[]): string => arr.join(', ');

export const getEncodedData = (data: Record<string, string>): string =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
