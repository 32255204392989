import React, { FC } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Button, { ButtonAppearance } from 'src/components/Button';
import Section from 'src/components/Section';
import { mediaQuery } from 'src/theme/breakpoints';
import { domain } from 'src/utils/constants';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  padding: 90px 0 0;

  ${mediaQuery('md')} {
    flex-direction: row-reverse;
  }

  ${mediaQuery('lg')} {
    padding: 120px 0 60px;
  }
`;

const ImageWrapper = styled.div`
  width: 60%;

  ${mediaQuery('md')} {
    margin-right: 50px;
  }
`;

const Text = styled.p`
  margin: -10px 0 40px;
  text-align: center;
`;

const VoucherPreview: FC = () => {
  return (
    <Wrapper>
      <Section title="Padovanok pojūčius">
        <Text>Rinkis Piligrimo dovanų kuponą</Text>
        <Button
          appearance={ButtonAppearance.ALFA}
          url={`${domain}/product/dovanu-kuponas/`}
          isExternal
        >
          Dovanų kuponas
        </Button>
      </Section>
      <ImageWrapper>
        <StaticImage
          src="../../assets/images/voucher.jpg"
          alt="Piligrimo dovanų kuponas"
        />
      </ImageWrapper>
    </Wrapper>
  );
};

export default VoucherPreview;
