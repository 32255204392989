import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { BeachIcon, LocationIcon, SkiIcon } from 'src/assets/icons';
import { mediaQuery } from 'src/theme/breakpoints';
import { colors } from 'src/theme/colors';
import { links } from 'src/components/MultistepForm/shared/constants';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  width: 100%;

  ${mediaQuery('sm')} {
    flex-direction: row;
    width: auto;
  } ;
`;

const Button = styled(Link)`
  align-items: center;
  background-color: ${colors.blue300};
  border-radius: 10px;
  color: ${colors.white};
  display: flex;
  height: 46px;
  justify-content: center;
  max-width: 260px;
  padding: 0 20px;
  text-decoration: none;
  transition: background-color 300ms;
  width: 100%;

  &:hover {
    background-color: ${colors.blue500};
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${mediaQuery('sm')} {
    border-radius: 0;
    width: auto;

    &:not(:last-child) {
      margin-bottom: 0px;
    }

    &:first-child {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }

    &:nth-child(2) {
      border-left: 1px solid ${colors.blue100};
      border-right: 1px solid ${colors.blue100};
    }

    &:last-child {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  & path {
    fill: ${colors.blue100};
  }
`;

const ButtonLabel = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-left: 12px;
  text-transform: uppercase;
`;

const ButtonsGroup: FC = () => (
  <Wrapper>
    <Button to={links.ACTIVITIES}>
      <SkiIcon />
      <ButtonLabel>Veiklas</ButtonLabel>
    </Button>
    <Button to={links.COUNTRIES}>
      <LocationIcon />
      <ButtonLabel>Šalis</ButtonLabel>
    </Button>
    <Button to={links.SEASONS}>
      <BeachIcon />
      <ButtonLabel>Sezoną</ButtonLabel>
    </Button>
  </Wrapper>
);

export default ButtonsGroup;
