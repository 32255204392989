import React, { FC } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Logo = styled.a`
  filter: grayscale(100%);
  height: auto;
  max-width: 90px;
  transition: filter 300ms;
  width: 100%;

  &:hover {
    filter: none;
  }

  & img {
    cursor: pointer;
  }
`;

interface PartnerProps {
  logo: any; //TODO
  title: string;
  url?: string;
}

const Partner: FC<PartnerProps> = ({ logo, title, url }) => (
  <Logo href={url} target="_blank" rel="noopener noreferrer">
    <GatsbyImage image={getImage(logo.localFile)} alt={title} />
  </Logo>
);

export default Partner;
