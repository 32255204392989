import React, { FC } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Button, { ButtonAppearance } from 'src/components/Button';
import Section from 'src/components/Section';
import { mediaQuery } from 'src/theme/breakpoints';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  padding: 90px 0 0;

  ${mediaQuery('md')} {
    flex-direction: row-reverse;
  }

  ${mediaQuery('lg')} {
    padding: 120px 0 60px;
  }
`;

const ImageWrapper = styled.div`
  width: 60%;

  ${mediaQuery('md')} {
    margin-right: 50px;
  }

  & img {
    border-radius: 22px;
  }
`;

const Text = styled.p`
  margin: -10px 0 40px;
  text-align: center;
`;

const NovaturasSection: FC = () => {
  return (
    <Wrapper>
      <Section title="Poilsinės Novaturo kelionės">
        <Text>Rinkitės mūsų partnerio Novaturas poilsines keliones</Text>
        <Button appearance={ButtonAppearance.ALFA} url={`/novaturo-keliones`}>
          Rinktis Novaturo kelionę
        </Button>
      </Section>
      <ImageWrapper>
        <StaticImage
          src="../../assets/images/poilsines-keliones.jpeg"
          alt="Poilsines Novaturas keliones."
        />
      </ImageWrapper>
    </Wrapper>
  );
};

export default NovaturasSection;
