import React, { FC } from 'react';
import styled from 'styled-components';

import Heading, { HeadingScope, HeadingVariant } from 'src/components/Heading';
import { colors } from 'src/theme/colors';

const Title = styled(Heading)<Pick<SectionProps, 'isCentered'>>`
  position: relative;
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};

  &:first-child {
    margin-top: 0;
  }

  &:after {
    background-color: ${colors.blue300};
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: ${({ isCentered }) => (isCentered ? 'calc(50% - 50px)' : '0')};
    position: absolute;
    width: 100px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 0;
`;

const Wrapper = styled.div`
  &:not(:first-child) {
    margin-top: 90px;
  }
`;

interface SectionProps {
  title: string;
  children: React.ReactNode;
  isCentered?: boolean;
}

const Section: FC<SectionProps> = ({
  title,
  children,
  isCentered = 'true',
}) => (
  <Wrapper>
    <HeadingScope>
      <Title isCentered={isCentered} variant={HeadingVariant.BRAVO}>
        {title}
      </Title>
      <Content>{children}</Content>
    </HeadingScope>
  </Wrapper>
);

export default Section;
