import React, { FC } from 'react';
import styled from 'styled-components';

import Partner from 'src/components/Partner';
import { mediaQuery } from 'src/theme/breakpoints';

const Grid = styled.div<PartnerGridProps>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  ${mediaQuery('sm', 'max')} {
    & > * {
      margin-top: 10px;
    }
  }

  ${mediaQuery('sm')} {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 20px;
  }
`;

interface PartnerGridProps {
  partners?: any;
}

const PartnerGrid: FC<PartnerGridProps> = ({ partners }) => (
  <Grid>
    {partners &&
      partners.map((partner, id) => (
        <Partner
          key={id}
          logo={partner.logo}
          title={partner.title}
          url={partner.link}
        />
      ))}
  </Grid>
);

export default PartnerGrid;
