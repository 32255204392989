import React, { FC } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  getMappedProducts,
  getStringifiedCategoryValues,
} from 'src/components/Product/shared/helpers';
import TravelCard from 'src/components/TravelCard';
import {
  categoryFields,
  links,
} from 'src/components/MultistepForm/shared/constants';
import { sliderSettings } from './settings';

const StyledSlider = styled(Slider)`
  & .slick-track {
    padding-top: 20px;
  }
`;

interface CardsSliderProps {
  cards: any;
}

const CardsSlider: FC<CardsSliderProps> = ({ cards }) => {
  const products = getMappedProducts(cards);

  return (
    <StyledSlider {...sliderSettings}>
      {products.map(product => (
        <TravelCard
          key={product.name}
          image={product.image}
          url={`${links.TRIPS}/${product.slug}`}
          tags={product.productTags}
          location={getStringifiedCategoryValues(
            product.productCategories[categoryFields.COUNTRIES]
          )}
          duration={`${product.productFields.days} d.`}
          title={product.name}
          regularPrice={
            product.productFields.fullPrice
              ? product.productFields.fullPrice
              : product.regularPrice
          }
          salePrice={
            product.productFields.fullPriceWithDiscount
              ? product.productFields.fullPriceWithDiscount
              : product.salePrice
          }
        />
      ))}
    </StyledSlider>
  );
};

export default CardsSlider;
